<template>
	<main class="font">
		<vue-title title="Macedonian Cyrillic Fonts (not unicode)"></vue-title>
		<div class="container">
			<div class="row mt-3">
				<div class="col-md-7 col-lg-8 pb-5">
					<h1>Macedonian Cyrillic Fonts <small>(not unicode)</small></h1>
					<div class="alert alert-info my-5">Macedonian Cyrillic Fonts is a repository of old, pre-unicode Macedonian fonts, which you can freely download. However, if you need to write text in Macedonian Cyrillic, it is better to use fonts with multi-language support, instead of the fonts listed below.</div>
					<div id="mc-fonts">
						<div class="row">
							<div class="col-sm pb-3">
								<select v-model="selectedFont" class="form-control">
									<option v-for="option in arrFonts" :key="option.value" :value="option.value">
										{{ option.text }}
									</option>
								</select>
								<small class="text-muted" v-html="fontXofY"></small>
							</div>
							<div class="col-sm-auto pb-3">
								<button class="btn btn-primary mr-1" @click="prev" :disabled="selectedFont < 2">Prev</button>
								<button class="btn btn-primary" @click="next" :disabled="selectedFont === (arrFonts.length - 1)">Next</button><br>
								<small class="text-muted">(or use arrow keys)</small>
							</div>
						</div>
						<p><img class="img-fluid" :src="selectedImgSrc" alt="Font preview"></p>
						<a v-if="selectedFont > 0" class="btn btn-primary" :href="selectedDownloadLink">Download</a>
					</div>
				</div>
				<div class="col-md-5 col-lg-4 pb-5">
					<div id="sidebar-tabs">
						<ul class="nav nav-tabs">
							<li class="nav-item">
								<a class="nav-link active" id="navlink1" href="#" @click.prevent="change(1)">What's this?</a>
							</li>
							<li class="nav-item">
								<a class="nav-link" id="navlink2" href="#" @click.prevent="change(2)">Ownership</a>
							</li>
						</ul>
						<div class="tab-content mt-4 small">
							<div class="tab-pane fade show active" id="tabpane1">
								<p>The fonts listed here are no longer in general use after multi-language support was added to the popular operating systems. These old fonts are now replaced with Unicode fonts, which besides the English Latin set of characters, include the Macedonian Cyrillic alphabet. However, if you still need to use any of them, browse the fonts presented here and download what you need.</p>
							</div>
							<div class="tab-pane fade" id="tabpane2">
								<p>Where present, I kept the original copyright notice and author's name. In case you have any questions about the fonts, please contact their authors, if that information exists when you open the font file.</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</main>
</template>


<script>
import changeTab from '../_js/changeTab.js';

const fontDefs = [
	{value: 0, slug: '_', text: ''},
	{value: 1, slug: 'Aachen_Kir_Light', text: 'Aachen Kir Light'},
	{value: 2, slug: 'Arabi', text: 'Arabi'},
	{value: 3, slug: 'Arabia_Cirilica', text: 'Arabia Cirilica'},
	{value: 4, slug: 'Ariston_Extra_Bold', text: 'Ariston Extra Bold'},
	{value: 5, slug: 'Black_Swiss_Kirilica_Light', text: 'Black Swiss Kirilica Light'},
	{value: 6, slug: 'Bodoni_Kir_Bold', text: 'Bodoni Kir Bold'},
	{value: 7, slug: 'Bodoni_Kirilica', text: 'Bodoni Kirilica'},
	{value: 8, slug: 'C_Arabia_Italic', text: 'C Arabia Italic'},
	{value: 9, slug: 'C_Broadway_Italic', text: 'C Broadway Italic'},
	{value: 10, slug: 'C_Helv_Italic', text: 'C Helv Italic'},
	{value: 11, slug: 'C_Helv_Plain', text: 'C Helv Plain'},
	{value: 12, slug: 'C_Mistral_Normal', text: 'C Mistral Normal'},
	{value: 13, slug: 'C_Renfrew_Italic', text: 'C Renfrew Italic'},
	{value: 14, slug: 'CHelv', text: 'CHelv'},
	{value: 15, slug: 'CHelv_Bold', text: 'CHelv Bold'},
	{value: 16, slug: 'CHelv_Bold_Italic', text: 'CHelv Bold Italic'},
	{value: 17, slug: 'CHelv_Italic', text: 'CHelv Italic'},
	{value: 18, slug: 'CHelv_Plain', text: 'CHelv Plain'},
	{value: 19, slug: 'Cir_Slavica_Italic', text: 'Cir Slavica Italic'},
	{value: 20, slug: 'Czar', text: 'Czar'},
	{value: 21, slug: 'Czar_Bold', text: 'Czar Bold'},
	{value: 22, slug: 'Czar_Bold_Italic', text: 'Czar Bold Italic'},
	{value: 23, slug: 'Czar_Italic', text: 'Czar Italic'},
	{value: 24, slug: 'Goga', text: 'Goga'},
	{value: 25, slug: 'Handel_GotMaKirilica_Normal', text: 'Handel GotMaKirilica Normal'},
	{value: 26, slug: 'Helv_Kirilica', text: 'Helv Kirilica'},
	{value: 27, slug: 'HelvCyrillic', text: 'HelvCyrillic'},
	{value: 28, slug: 'HelvCyrillic_Bold', text: 'HelvCyrillic Bold'},
	{value: 29, slug: 'HelvCyrillic_Bold_Italic', text: 'HelvCyrillic Bold Italic'},
	{value: 30, slug: 'HelvCyrillic_Italic', text: 'HelvCyrillic Italic'},
	{value: 31, slug: 'Helvetica_Cirilica', text: 'Helvetica Cirilica'},
	{value: 32, slug: 'Helvetica_Kirilica', text: 'Helvetica Kirilica'},
	{value: 33, slug: 'HV3MCB', text: 'HV3MCB'},
	{value: 34, slug: 'HV3MCBI', text: 'HV3MCBI'},
	{value: 35, slug: 'HV3MCI', text: 'HV3MCI'},
	{value: 36, slug: 'HV3MCN', text: 'HV3MCN'},
	{value: 37, slug: 'HV3MCN_Light', text: 'HV3MCN Light'},
	{value: 38, slug: 'HV3SCN', text: 'HV3SCN'},
	{value: 39, slug: 'Kir_Bold_Light', text: 'Kir Bold Light'},
	{value: 40, slug: 'Kir_TMSB_Bold', text: 'Kir TMSB Bold'},
	{value: 41, slug: 'Kir_TMSN_Light', text: 'Kir TMSN Light'},
	{value: 42, slug: 'Kristina', text: 'Kristina'},
	{value: 43, slug: 'Krstozbor', text: 'Krstozbor'},
	{value: 44, slug: 'M_Banff_Light', text: 'M Banff Light'},
	{value: 45, slug: 'M_Bedrock', text: 'M Bedrock'},
	{value: 46, slug: 'M_Bodoni', text: 'M Bodoni'},
	{value: 47, slug: 'M_Bodoni_Bold', text: 'M Bodoni Bold'},
	{value: 48, slug: 'M_Bodoni_Bold_Italic', text: 'M Bodoni Bold Italic'},
	{value: 49, slug: 'M_Bodoni_Italic', text: 'M Bodoni Italic'},
	{value: 50, slug: 'M_Cooper_Light', text: 'M Cooper Light'},
	{value: 51, slug: 'M_Copperplate', text: 'M Copperplate'},
	{value: 52, slug: 'M_Garamond', text: 'M Garamond'},
	{value: 53, slug: 'M_Garamond_Bold', text: 'M Garamond Bold'},
	{value: 54, slug: 'M_Garamond_Bold_Italic', text: 'M Garamond Bold Italic'},
	{value: 55, slug: 'M_Garamond_Italic', text: 'M Garamond Italic'},
	{value: 56, slug: 'M_Glasnost_Bold', text: 'M Glasnost Bold'},
	{value: 57, slug: 'M_Linos_Light', text: 'M Linos Light'},
	{value: 58, slug: 'M_Mirosi', text: 'M Mirosi'},
	{value: 59, slug: 'M_Motor', text: 'M Motor'},
	{value: 60, slug: 'M_Murmansk', text: 'M Murmansk'},
	{value: 61, slug: 'M_Odessa', text: 'M Odessa'},
	{value: 62, slug: 'M_Svoboda', text: 'M Svoboda'},
	{value: 63, slug: 'M_Svoboda_Bold', text: 'M Svoboda Bold'},
	{value: 64, slug: 'M_Svoboda_Bold_Italic', text: 'M Svoboda Bold Italic'},
	{value: 65, slug: 'M_Svoboda_Italic', text: 'M Svoboda Italic'},
	{value: 66, slug: 'M_Vremya', text: 'M Vremya'},
	{value: 67, slug: 'M_Vremya_Bold', text: 'M Vremya Bold'},
	{value: 68, slug: 'M_Vremya_Bold_Italic', text: 'M Vremya Bold Italic'},
	{value: 69, slug: 'M_Vremya_Italic', text: 'M Vremya Italic'},
	{value: 70, slug: 'Mac_C_Swiss', text: 'Mac C Swiss'},
	{value: 71, slug: 'Mac_C_Swiss_Bold', text: 'Mac C Swiss Bold'},
	{value: 72, slug: 'Mac_C_Swiss_Bold_Italic', text: 'Mac C Swiss Bold Italic'},
	{value: 73, slug: 'Mac_C_Swiss_Italic', text: 'Mac C Swiss Italic'},
	{value: 74, slug: 'Mac_C_Times', text: 'Mac C Times'},
	{value: 75, slug: 'Mac_C_Times_Bold', text: 'Mac C Times Bold'},
	{value: 76, slug: 'Mac_C_Times_Bold_Italic', text: 'Mac C Times Bold Italic'},
	{value: 77, slug: 'Mac_C_Times_Italic', text: 'Mac C Times Italic'},
	{value: 78, slug: 'Mac_Cooper', text: 'Mac Cooper'},
	{value: 79, slug: 'Mac_Helvetica_Bold', text: 'Mac Helvetica Bold'},
	{value: 80, slug: 'Macedonian', text: 'Macedonian'},
	{value: 81, slug: 'Macedonian_8Pin_Matrix', text: 'Macedonian 8Pin Matrix'},
	{value: 82, slug: 'Macedonian_Ancient', text: 'Macedonian Ancient'},
	{value: 83, slug: 'Macedonian_Arial', text: 'Macedonian Arial'},
	{value: 84, slug: 'Macedonian_Ariston', text: 'Macedonian Ariston'},
	{value: 85, slug: 'Macedonian_Artistic', text: 'Macedonian Artistic'},
	{value: 86, slug: 'Macedonian_Astra', text: 'Macedonian Astra'},
	{value: 87, slug: 'Macedonian_Bahamas', text: 'Macedonian Bahamas'},
	{value: 88, slug: 'Macedonian_Bamboo', text: 'Macedonian Bamboo'},
	{value: 89, slug: 'Macedonian_Becker', text: 'Macedonian Becker'},
	{value: 90, slug: 'Macedonian_Bodoni_Normal', text: 'Macedonian Bodoni Normal'},
	{value: 91, slug: 'Macedonian_Broadway', text: 'Macedonian Broadway'},
	{value: 92, slug: 'Macedonian_Casual', text: 'Macedonian Casual'},
	{value: 93, slug: 'Macedonian_Chancellor', text: 'Macedonian Chancellor'},
	{value: 94, slug: 'Macedonian_Chicago', text: 'Macedonian Chicago'},
	{value: 95, slug: 'Macedonian_Church', text: 'Macedonian Church'},
	{value: 96, slug: 'Macedonian_Classic', text: 'Macedonian Classic'},
	{value: 97, slug: 'Macedonian_Cond_50', text: 'Macedonian Cond 50'},
	{value: 98, slug: 'Macedonian_Cond_50_Italic', text: 'Macedonian Cond 50 Italic'},
	{value: 99, slug: 'Macedonian_Cond_60', text: 'Macedonian Cond 60'},
	{value: 100, slug: 'Macedonian_Cond_60_Italic', text: 'Macedonian Cond 60 Italic'},
	{value: 101, slug: 'Macedonian_Cond_70', text: 'Macedonian Cond 70'},
	{value: 102, slug: 'Macedonian_Cond_70_Italic', text: 'Macedonian Cond 70 Italic'},
	{value: 103, slug: 'Macedonian_Cond_80', text: 'Macedonian Cond 80'},
	{value: 104, slug: 'Macedonian_Cond_80_Italic', text: 'Macedonian Cond 80 Italic'},
	{value: 105, slug: 'Macedonian_Copper', text: 'Macedonian Copper'},
	{value: 106, slug: 'Macedonian_Corrida', text: 'Macedonian Corrida'},
	{value: 107, slug: 'Macedonian_Courier', text: 'Macedonian Courier'},
	{value: 108, slug: 'Macedonian_Cupertino', text: 'Macedonian Cupertino'},
	{value: 109, slug: 'Macedonian_Dutch_Normal', text: 'Macedonian Dutch Normal'},
	{value: 110, slug: 'Macedonian_Eco_Condensed_60', text: 'Macedonian Eco Condensed 60'},
	{value: 111, slug: 'Macedonian_Eco_Condensed_70', text: 'Macedonian Eco Condensed 70'},
	{value: 112, slug: 'Macedonian_Elegant', text: 'Macedonian Elegant'},
	{value: 113, slug: 'Macedonian_Europe', text: 'Macedonian Europe'},
	{value: 114, slug: 'Macedonian_Futuris', text: 'Macedonian Futuris'},
	{value: 115, slug: 'Macedonian_Garamond', text: 'Macedonian Garamond'},
	{value: 116, slug: 'Macedonian_Gothic', text: 'Macedonian Gothic'},
	{value: 117, slug: 'Macedonian_Handwriting', text: 'Macedonian Handwriting'},
	{value: 118, slug: 'Macedonian_Heavy', text: 'Macedonian Heavy'},
	{value: 119, slug: 'Macedonian_Helv', text: 'Macedonian Helv'},
	{value: 120, slug: 'Macedonian_Helv_Bold', text: 'Macedonian Helv Bold'},
	{value: 121, slug: 'Macedonian_Helv_Bold_Italic', text: 'Macedonian Helv Bold Italic'},
	{value: 122, slug: 'Macedonian_Helv_Italic', text: 'Macedonian Helv Italic'},
	{value: 123, slug: 'Macedonian_Humanist', text: 'Macedonian Humanist'},
	{value: 124, slug: 'Macedonian_Karina', text: 'Macedonian Karina'},
	{value: 125, slug: 'Macedonian_LCD', text: 'Macedonian LCD'},
	{value: 126, slug: 'Macedonian_Penguin', text: 'Macedonian Penguin'},
	{value: 127, slug: 'Macedonian_Poster', text: 'Macedonian Poster'},
	{value: 128, slug: 'Macedonian_Prestige', text: 'Macedonian Prestige'},
	{value: 129, slug: 'Macedonian_Revue', text: 'Macedonian Revue'},
	{value: 130, slug: 'Macedonian_Saloon', text: 'Macedonian Saloon'},
	{value: 131, slug: 'Macedonian_Times_New', text: 'Macedonian Times New'},
	{value: 132, slug: 'Macedonian_Tms', text: 'Macedonian Tms'},
	{value: 133, slug: 'Macedonian_Tms_Bold', text: 'Macedonian Tms Bold'},
	{value: 134, slug: 'Macedonian_Tms_Bold_Italic', text: 'Macedonian Tms Bold Italic'},
	{value: 135, slug: 'Macedonian_Tms_Italic', text: 'Macedonian Tms Italic'},
	{value: 136, slug: 'Macedonian_Typewriter', text: 'Macedonian Typewriter'},
	{value: 137, slug: 'Macedonian_Unicorn', text: 'Macedonian Unicorn'},
	{value: 138, slug: 'Macedonian_University', text: 'Macedonian University'},
	{value: 139, slug: 'Macedonian_Xenia_Bold', text: 'Macedonian Xenia Bold'},
	{value: 140, slug: 'Mak_CirT', text: 'Mak CirT'},
	{value: 141, slug: 'Mak_CirT_Bold', text: 'Mak CirT Bold'},
	{value: 142, slug: 'Mak_CirT_Bold_Italic', text: 'Mak CirT Bold Italic'},
	{value: 143, slug: 'Mak_CirT_Italic', text: 'Mak CirT Italic'},
	{value: 144, slug: 'Mak_Czar', text: 'Mak Czar'},
	{value: 145, slug: 'Mak_Hebar_Black_Normal', text: 'Mak Hebar Black Normal'},
	{value: 146, slug: 'Mak_Hebar_Narrow_Bold_Italic', text: 'Mak Hebar Narrow Bold Italic'},
	{value: 147, slug: 'Mak_Hebar_Narrow_Normal', text: 'Mak Hebar Narrow Normal'},
	{value: 148, slug: 'Mak_Hebar_Narrow_Normal_Bold', text: 'Mak Hebar Narrow Normal Bold'},
	{value: 149, slug: 'Mak_Hebar_Narrow_Normal_Bold_Italic', text: 'Mak Hebar Narrow Normal Bold Italic'},
	{value: 150, slug: 'Mak_Hebar_Narrow_Normal_Italic', text: 'Mak Hebar Narrow Normal Italic'},
	{value: 151, slug: 'Mak_Hebar_Normal', text: 'Mak Hebar Normal'},
	{value: 152, slug: 'MakBodoni_Poster', text: 'MakBodoni Poster'},
	{value: 153, slug: 'Makedonska_Helvetika', text: 'Makedonska Helvetika'},
	{value: 154, slug: 'Makedonska_Helvetika_Italic', text: 'Makedonska Helvetika Italic'},
	{value: 155, slug: 'Makedonski_Garamond_Bold', text: 'Makedonski Garamond Bold'},
	{value: 156, slug: 'Makedonski_Garamond_Bold_Italic', text: 'Makedonski Garamond Bold Italic'},
	{value: 157, slug: 'Makedonski_Garamond_Italic', text: 'Makedonski Garamond Italic'},
	{value: 158, slug: 'Makedonski_Garamond_Normal', text: 'Makedonski Garamond Normal'},
	{value: 159, slug: 'Makedonski_Vodno', text: 'Makedonski Vodno'},
	{value: 160, slug: 'Makedonsko_Rakopisno_Italic', text: 'Makedonsko Rakopisno Italic'},
	{value: 161, slug: 'Matka', text: 'Matka'},
	{value: 162, slug: 'Matka_Bold', text: 'Matka Bold'},
	{value: 163, slug: 'Matka_Bold_Italic', text: 'Matka Bold Italic'},
	{value: 164, slug: 'Matka_Italic', text: 'Matka Italic'},
	{value: 165, slug: 'Mermaid_Cir', text: 'Mermaid Cir'},
	{value: 166, slug: 'Pulshelvetika7', text: 'Pulshelvetika7'},
	{value: 167, slug: 'Pulshelvetika7_Bold', text: 'Pulshelvetika7 Bold'},
	{value: 168, slug: 'Pulshelvetika7_Bold_Italic', text: 'Pulshelvetika7 Bold Italic'},
	{value: 169, slug: 'Pulshelvetika7_Italic', text: 'Pulshelvetika7 Italic'},
	{value: 170, slug: 'Pulstajms7', text: 'Pulstajms7'},
	{value: 171, slug: 'Pulstajms7_Bold', text: 'Pulstajms7 Bold'},
	{value: 172, slug: 'Pulstajms7_Bold_Italic', text: 'Pulstajms7 Bold Italic'},
	{value: 173, slug: 'Pulstajms7_Italic', text: 'Pulstajms7 Italic'},
	{value: 174, slug: 'Rakopisno_Pismo', text: 'Rakopisno Pismo'},
	{value: 175, slug: 'Rakopisno_Pismo_Normal_Italic', text: 'Rakopisno Pismo Normal Italic'},
	{value: 176, slug: 'Staro_Makedonsko_Pismo', text: 'Staro Makedonsko Pismo'},
	{value: 177, slug: 'Staro_Makedonsko_Pismo_Bold', text: 'Staro Makedonsko Pismo Bold'},
	{value: 178, slug: 'Staroslovenska_Light', text: 'Staroslovenska Light'},
	{value: 179, slug: 'Svis_Mak', text: 'Svis Mak'},
	{value: 180, slug: 'Swiss_Kirilica_Normal', text: 'Swiss Kirilica Normal'},
	{value: 181, slug: 'Swiss_Pro_Kirilica', text: 'Swiss Pro Kirilica'},
	{value: 182, slug: 'Treska', text: 'Treska'},
	{value: 183, slug: 'Treska_Bold', text: 'Treska Bold'},
	{value: 184, slug: 'Treska_Bold_Italic', text: 'Treska Bold Italic'},
	{value: 185, slug: 'Treska_Italic', text: 'Treska Italic'},
	{value: 186, slug: 'University_Kirilica_Light', text: 'University Kirilica Light'},
	{value: 187, slug: 'Vodno_Bold', text: 'Vodno Bold'},
	{value: 188, slug: 'Vodno_Italic', text: 'Vodno Italic'},
	{value: 189, slug: 'Vodno_Normal', text: 'Vodno Normal'}
];

export default {
	name: "font",
	data: function() {
		return {
			selectedFont: 0,
			arrFonts: fontDefs
		}
	},
	created: function () {
		window.addEventListener('keyup', this.keyboardNavigate);
	},
	computed: {
		selectedImgSrc: function () {
			return 'img/fonts/' + this.arrFonts[this.selectedFont].slug + '.png';
		},
		selectedDownloadLink: function () {
			return 'font-download.php?f=' + this.arrFonts[this.selectedFont].slug + '.ttf';
		},
		fontXofY: function () {
			return this.selectedFont + ' of ' + (fontDefs.length - 1);
		}
	},
	methods: {
		prev: function () {
			if (this.selectedFont > 1) {
				this.selectedFont--;
			}
		},
		next: function () {
			if (this.selectedFont < (this.arrFonts.length - 1)) {
				this.selectedFont++;
			}
		},
		keyboardNavigate: function () {
			if (event.which === 37) {
				this.prev();
			}
			if (event.which === 39) {
				this.next();
			}
		},
		change: function(id) {
			changeTab.show(id);
		}
	}
}
</script>